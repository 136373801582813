/*eslint-disable*/
import React from "react"

import ParallaxSection from "components/ParallaxSection"
// nodejs library that concatenates classes
import classNames from "classnames"
// react component used to create nice image meadia player
import ImageGallery from "react-image-gallery"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"
// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Accordion from "components/Accordion/Accordion.js"
import LinearProgress from '@material-ui/core/LinearProgress';
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"

import {Link} from "gatsby"


import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

import { gql, useQuery } from "@apollo/client"

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"


import PageLayout from "../components/PageLayout"

const useStyles = makeStyles(productStyle)

const ProjectPage = (props) => {

  const { cmsURL, baseImageURL, mediaURL } = useSiteMetadata()

  const title = props["*"]
  console.log(props)

  const PROJECTS_QUERY = gql`
    {
      projectsCollection(filter: { title_slug: "${title}" }) {
        _id
        title
        title_slug
        image{
            path
        }
        excerpt
        content
        gallery {
          path
          meta
        }
      }
    }
  `

  const classes = useStyles()

  const {loading, error, data} = useQuery(PROJECTS_QUERY)
  
  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  if (!data.projectsCollection) {
    data.projectsCollection = []
  }


  const images = data.projectsCollection[0].gallery.map(image => ({
    original: mediaURL + image.path,
    thumbnail: mediaURL + image.path,
    // original: cmsURL + image.path,
    // thumbnail: cmsURL + image.path,

  }))


  const header_image = data.projectsCollection[0].image
  ? mediaURL+"storage/uploads"+data.projectsCollection[0].image.path
  // ? baseImageURL+data.projectsCollection[0].image.path
  : images[0].original

  return (
    <div className={classes.productPage}>
      <PageLayout>
      <SEO
          title={data.projectsCollection[0].title}
          description={data.projectsCollection[0].title}
          image={header_image}
          pathname={location.pathname}
        />
        <ParallaxSection
          image={header_image}
          filter="dark"
          className={classes.pageHeader}
        />

        <div className={classNames(classes.section, classes.sectionGray)}>
          <div className={classes.container}>
            <div className={classNames(classes.main, classes.mainRaised)}>
            <Breadcrumbs aria-label="breadcrumb" style={{marginBottom:"2rem"}}>
                <Link color="inherit" to="/">
                  Αρχική
                </Link>
                <Link color="inherit" to="/projects">
                  Έργα
                </Link>
                <Typography color="textPrimary">{data.projectsCollection[0].title}</Typography>
              </Breadcrumbs>
              <GridContainer>
              <h2 className={classes.title}>
                    {data.projectsCollection[0].title}
                  </h2>
                <GridItem>
                {/* <GridItem md={6} sm={6}> */}
                  <ImageGallery
                    showNav={false}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    startIndex={0}
                    items={images}
                    // autoPlay={true}
                    useBrowserFullscreen={false}
                  />
                </GridItem>
                {/* <GridItem md={6} sm={6}>
                  <h2 className={classes.title}>
                    {data.projectsCollection[0].title}
                  </h2>
                  <h3 className={classes.mainPrice}>Subtitle</h3>
                  <Accordion
                    active={0}
                    activeColor="rose"
                    collapses={[
                      {
                        title: "Το έργο",
                        content: <p>{data.projectsCollection[0].content}</p>,
                      },
                      {
                        title: "Η δουλειά μας",
                        content: (
                          <p>
                            An infusion of West Coast cool and New York
                            attitude, Rebecca Minkoff is synonymous with It girl
                            style. Minkoff burst on the fashion scene with her
                            best-selling {"'"}Morning After Bag{"'"} and later
                            expanded her offering with the Rebecca Minkoff
                            Collection - a range of luxe city staples with a{" "}
                            {'"'}
                            downtown romantic{'"'} theme.
                          </p>
                        ),
                      },
                      {
                        title: "Σχόλια πελάτη",
                        content: (
                          <ul>
                            <li>
                              Storm and midnight-blue stretch cotton-blend
                            </li>
                            <li>
                              Notch lapels, functioning buttoned cuffs, two
                              front flap pockets, single vent, internal pocket
                            </li>
                            <li>Two button fastening</li>
                            <li>84% cotton, 14% nylon, 2% elastane</li>
                            <li>Dry clean</li>
                          </ul>
                        ),
                      },
                    ]}
                  />
                </GridItem> */}
              </GridContainer>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default ProjectPage
