import { useStaticQuery, graphql } from 'gatsby';


const useSiteMetadata = () => {
    const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
          siteUrl
          cmsURL
          logo
          logo_black
          baseImageURL
          mediaURL
        }
      }
    }
  `
    );
    return site.siteMetadata;
  };
  
  export default useSiteMetadata;